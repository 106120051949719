var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"simple"},[_c('b-form',[_c('b-card-code',{attrs:{"title":"إضافة مستودع"}},[_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-row',[_c('b-col',{attrs:{"md":"3","xl":"3"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"title","state":errors.length > 0 ? false : null}},[_c('label',[_vm._v(" الاسم")]),_c('b-form-input',{attrs:{"type":"text","id":"input-title"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('b-col',{attrs:{"md":"3","xl":"3"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"code","state":errors.length > 0 ? false : null}},[_c('label',[_vm._v(" الموقع")]),_c('b-form-input',{attrs:{"type":"text","id":"input-default"},model:{value:(_vm.location),callback:function ($$v) {_vm.location=$$v},expression:"location"}})],1),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('b-col',{attrs:{"md":"3","xl":"3"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"code","state":errors.length > 0 ? false : null}},[_c('label',[_vm._v(" الكود")]),_c('b-form-input',{attrs:{"type":"text","id":"input-default"},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}})],1),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('b-col',{attrs:{"md":"3","xl":"3"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"employe","state":errors.length > 0 ? false : null}},[_c('label',[_vm._v(" نوع المستودغ")]),_c('v-select',{attrs:{"label":"name","options":_vm.optionsWareHouse,"reduce":function (val) { return val.id; }},on:{"input":function($event){return _vm.getWarehouseById(_vm.warehouse_type)}},model:{value:(_vm.warehouse_type),callback:function ($$v) {_vm.warehouse_type=$$v},expression:"warehouse_type"}})],1),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),((_vm.warehouse_type==1))?_c('b-col',{attrs:{"md":"3","xl":"3"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"employe","state":errors.length > 0 ? false : null}},[_c('label',[_vm._v(" المستودع")]),_c('v-select',{attrs:{"label":"name","options":_vm.optionsWarehouse,"reduce":function (val) { return val.id; }},model:{value:(_vm.parent_id),callback:function ($$v) {_vm.parent_id=$$v},expression:"parent_id"}})],1),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1):_vm._e(),_c('b-col',{attrs:{"md":"1","xl":"3"}},[_c('b-button',{attrs:{"variant":"purple"},on:{"click":_vm.addWarehouse}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"PlusIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v(" إضافة المستودع ")])],1)],1)],1)]}}])})],1)],1)],1),_c('b-card-code',{attrs:{"title":" المستودعات"}},[_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"search-options":{
        enabled: true,
        externalQuery: _vm.searchTerm,
      }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'name')?_c('span',{staticClass:"text-nowrap"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(props.row.name))])]):(props.column.field === 'id')?_c('span',[_c('span',[_c('b-dropdown',{attrs:{"variant":"link","toggle-class":"text-decoration-none","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"text-body align-middle mr-25",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[_c('b-dropdown-item-button',{on:{"click":function($event){return _vm.$router.push({
                    name: 'edit-warehouse',
                    params: { id: props.row.id },
                  })}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"EyeIcon"}}),_c('span',[_vm._v("عرض")])],1),_c('b-dropdown-item-button',{on:{"click":function($event){return _vm.deleteWarehouse(props.row.id)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"TrashIcon"}}),_c('span',[_vm._v("حذف")])],1)],1)],1)]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }